import React from "react";
import ReactDOM from "react-dom/client";
import Practice from "../components/Practice.jsx";
import { createIntersect } from "./intersection.js";

const $target = document.getElementById("practice");

const render = () => {
  ReactDOM.createRoot($target).render(
    <React.StrictMode>
      <Practice />
    </React.StrictMode>,
  );
};

createIntersect($target, render);
