import Carousel from "@/components/types/Carousel";

function Practice() {
  const {
    components: {
      exercises: { component_ids, by_id },
    },
  } = window;

  return <Carousel kind="exercise" componentIds={component_ids} byId={by_id} />;
}

export default Practice;
